









import { Component, Prop, Vue } from 'vue-property-decorator';

import ProgressBar from '@/components/progress_bar.vue';

@Component({
  components: {
    ProgressBar,
  }
})
export default class  extends Vue {
  @Prop({default: null, type: Number})
  progress!: number | null;
}
