










































































import { Component, Prop, Vue } from 'vue-property-decorator';

import ProgressBar from './progress_bar.vue';
import Toggle from './toggle.vue';


interface DiffCellData {
  line_number: number | null;
  prefix: string;
  content: string;
}

export class DiffPrefixError extends Error {}

@Component({
  components: {
    ProgressBar,
    Toggle,
  }
})
export default class Diff extends Vue {

  @Prop({default: Promise.resolve([]), type: Promise})
  diff_contents!: Promise<string[]>;

  @Prop({default: "", type: String})
  left_header!: string;

  @Prop({default: "", type: String})
  right_header!: string;

  @Prop({default: '100%', type: String})
  diff_max_height!: string;

  // A number from 0 to 100 that will be displayed as
  // the progress in loading diff_contents.
  @Prop({default: null, type: Number})
  progress!: number | null;

  // IMPORTANT: We are intentionally making these member variables NON-REACTIVE
  // by not initializing them here. This is very important for performance.
  // Indexing into large reactive arrays in the template will significantly
  // increase render times.
  private left!: DiffCellData[];
  private right!: DiffCellData[];

  private get left_with_whitespace() {
    return this.left.map(cell_data => {
      return {
        line_number: cell_data.line_number,
        prefix: cell_data.prefix,
        content: this.replace_whitespace(cell_data.content)
      };
    });
  }

  private get right_with_whitespace() {
    return this.right.map(cell_data => {
      return {
        line_number: cell_data.line_number,
        prefix: cell_data.prefix,
        content: this.replace_whitespace(cell_data.content)
      };
    });
  }

  d_show_whitespace = false;
  d_fullscreen = false;

  d_loading = true;

  readonly num_lines_per_page = 1000;
  d_num_lines_rendered = this.num_lines_per_page;

  async created() {
    let left_line_number = 1;
    let right_line_number = 1;

    this.left = [];
    this.right = [];

    for (let item of await this.diff_contents) {
      let prefix = item.substring(0, 2);
      let content = item.substring(2);
      if (prefix === "- ") {
        this.left.push({line_number: left_line_number, prefix: prefix, content: content});
        left_line_number += 1;
      }
      else if (prefix === "  ") {
        this.pad_if_needed(this.left, this.right);

        this.left.push({line_number: left_line_number, prefix: prefix, content: content});
        this.right.push({line_number: right_line_number, prefix: prefix, content: content});

        left_line_number += 1;
        right_line_number += 1;
      }
      else if (prefix === "+ ") {
        this.right.push({line_number: right_line_number, prefix: prefix, content: content});
        right_line_number += 1;
      }
      else {  // Treat invalid prefixes as "+ "
        this.right.push({line_number: right_line_number, prefix: "+ ", content: item});
        right_line_number += 1;
      }
    }
    this.pad_if_needed(this.left, this.right);

    this.d_loading = false;
 }

  pad_if_needed(left: DiffCellData[], right: DiffCellData[]) {
    if (left.length === right.length) {
      return;
    }
    let to_pad: DiffCellData[];
    let bigger: DiffCellData[];
    if (left.length > right.length) {
      bigger = left;
      to_pad = right;
    }
    else {
      bigger = right;
      to_pad = left;
    }
    while (to_pad.length < bigger.length) {
      to_pad.push({line_number: null, prefix: ' ', content: ''});
    }
  }

  readonly line_num_highlighting = {
    '- ': 'negative-line-num',
    '+ ': 'positive-line-num',
    '  ': ''
  };

  readonly content_highlighting = {
    '- ': 'negative',
    '+ ': 'positive',
    '  ': ''
  };

  get whitespace_regex() {
    // Some browsers might not yet support \p (unicode property escapes)
    try {
      // Match whitespace sequences and the "Other" unicode property category
      // https://unicode.org/reports/tr18/#General_Category_Property
      return new RegExp('[ \t\n\r\b\f\v\0]|\\p{C}', 'gu');
    }
    catch (e) {
      // It's unclear how/maybe impossible to mock a constructor call, so we
      // don't have a unit test for this fallback behavior currently.
      // istanbul ignore next
      return new RegExp('[ \t\n\r\b\f\v\0]', 'g');
    }
  }

  replace_whitespace(str: string): string {
    return str.replace(this.whitespace_regex, (matched) => {
      if (matched in this.special_char_replacements) {
        return this.special_char_replacements[matched];
      }

      // Replace "Other" unicode characters with their escape sequences
      let unpadded_char_code = matched.charCodeAt(0).toString(16);
      let num_leading_zeros = Math.max(0, 4 - unpadded_char_code.length);
      return `\\u${('0'.repeat(num_leading_zeros) + unpadded_char_code)}`;
    });
  }

  readonly special_char_replacements: {[key: string]: string} = {
    ' ': '\u2219',
    '\t': '\u21e5\t',
    '\n': '\u21b5\n',
    '\r': '\\r\r',
    '\b': '\\b',  // backspace
    '\f': '\\f',  // form-feed
    '\v': '\\v',  // vertical tab
    '\0': '\\0',  // null character
  };

  private get num_lines_to_show() {
    return Math.min(this.d_num_lines_rendered, this.left.length);
  }

  private render_more_lines() {
    this.d_num_lines_rendered = Math.min(
      this.left.length,
      this.d_num_lines_rendered + this.num_lines_per_page
    );
  }

  private get line_num_width() {
    return `${this.num_lines_to_show.toString().length + 1}ch`;
  }
}
