





















import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

@Component
export default class Toggle extends Vue {

  @Prop({default: false, type: Boolean})
  value!: boolean;

  @Watch('value')
  on_value_changed(new_value: boolean, old_value: boolean) {
    this.is_on = new_value;
  }

  @Prop({default: 'hsl(208, 59%, 49%)', type: String})
  active_background_color!: string;

  is_on: boolean = false;

  created() {
    this.is_on = this.value;
  }

  private _toggle() {
    this.is_on = !this.is_on;
    this.$emit('input', this.is_on);
  }
}
