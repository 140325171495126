
























import { Component, Prop, Vue } from 'vue-property-decorator';

import { ExpectedStudentFile, ExpectedStudentFileObserver, Project } from 'ag-client-typescript';

import CreateExpectedStudentFile from '@/components/project_admin/expected_student_files/create_expected_student_file.vue';
import SingleExpectedStudentFile from '@/components/project_admin/expected_student_files/single_expected_student_file.vue';
import Tooltip from '@/components/tooltip.vue';
import { deep_copy } from '@/utils';

@Component({
  components: {
    CreateExpectedStudentFile,
    SingleExpectedStudentFile,
    Tooltip
  }
})
export default class ExpectedStudentFiles extends Vue {

  @Prop({required: true, type: Project})
  project!: Project;

  // Do NOT modify the contents of this array!!
  get expected_student_files(): ReadonlyArray<Readonly<ExpectedStudentFile>> {
    return this.project.expected_student_files;
  }
}
