





























import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

import {
  AGTestCommandFeedbackConfig,
  AGTestSuiteFeedbackConfig
} from 'ag-client-typescript';

import {
  AGTestCommandFeedbackPreset,
  AGTestSuiteFeedbackPreset,
  MutationTestSuiteFeedbackPreset
} from '@/components/project_admin/feedback_config_panel/feedback_config_utils';
import { SafeMap } from '@/safe_map';
import { safe_assign } from "@/utils";

export type FeedbackPresetType = MutationTestSuiteFeedbackPreset
                                 | AGTestCommandFeedbackPreset
                                 | AGTestSuiteFeedbackPreset;

export type FeedbackConfigType = AGTestCommandFeedbackConfig
                                 | AGTestSuiteFeedbackConfig
                                 | MutationTestSuiteFeedbackPreset
                                 | null;
@Component
export default class FeedbackConfigPanel extends Vue {
  @Prop({default: null, type: SafeMap})
  preset_options!: SafeMap<string, FeedbackPresetType> | null;

  @Prop({required: false, type: Object})
  value!: FeedbackConfigType | null;

  d_configuration: FeedbackConfigType | null = null;
  preset_names: string[] = [];
  get selected_preset_name() {
    return this.d_selected_preset_name;
  }
  d_selected_preset_name: string = "Custom";

  @Watch('value', {deep: true})
  on_value_changed(new_value: FeedbackConfigType, old_value: FeedbackConfigType) {
    this.set_d_configuration(new_value);
  }

  created() {
    if (this.preset_options !== null) {
      for (let [preset_label, preset_settings] of this.preset_options) {
        this.preset_names.push(preset_label);
      }
    }
    this.set_d_configuration(this.value);
  }

  private get is_enabled() {
    return this.d_configuration !== null && this.preset_names.length !== 0;
  }

  private set_d_configuration(feedback_config: FeedbackConfigType) {
    this.d_configuration = JSON.parse(JSON.stringify(feedback_config));
    this.d_selected_preset_name = this.detect_current_preset();
  }

  private detect_current_preset(): string {
    if (this.preset_options === null) {
      return '';
    }

    for (let [label, preset] of this.preset_options) {
      if (this.config_matches_preset(this.d_configuration, preset)) {
        return label;
      }
    }
    return "Custom";
  }

  private config_matches_preset(config: FeedbackConfigType, preset: FeedbackPresetType) {
    if (config === null) {
      return false;
    }

    // We have to use the keys of preset because they do not have the "visible"
    // option that the config has.
    for (let key of Object.keys(preset)) {
      // @ts-ignore
      if (config[key] !== preset[key]) {
        return false;
      }
    }

    return true;
  }

  private change_preset() {
    console.assert(this.d_selected_preset_name !== "Custom");
    let updated_config = JSON.parse(JSON.stringify(this.d_configuration));
    safe_assign(updated_config, this.preset_options!.get(this.d_selected_preset_name));
    this.$emit('input', updated_config);
  }
}
