


















import { Component, Prop, Vue } from 'vue-property-decorator';

import { HandgradingResult } from 'ag-client-typescript';

import ViewFile from '@/components/view_file.vue';
import { toggle } from '@/utils';

@Component({
  components: {
    ViewFile
  }
})
export default class FilePanel extends Vue {
  @Prop({required: true, type: HandgradingResult})
  handgrading_result!: HandgradingResult;

  @Prop({required: true, type: String})
  filename!: string;

  @Prop({required: true, type: Boolean})
  enable_custom_comments!: boolean;

  // When true, editing handgrading results will be disabled.
  @Prop({required: true, type: Boolean})
  readonly_handgrading_results!: boolean;

  d_is_open = false;

  d_content: Promise<string> | null = null;
  d_progress: number | null = null;

  toggle_open() {
    let top = this.$el.getBoundingClientRect().top;

    this.d_progress = null;
    this.d_is_open = !this.d_is_open;
    if (this.d_content === null) {
      this.d_content = HandgradingResult.get_file_from_handgrading_result(
        this.handgrading_result.group, this.filename,
        (event: ProgressEvent) => {
          if (event.lengthComputable) {
            this.d_progress = 100 * (1.0 * event.loaded / event.total);
          }
        }
      );
    }

    // This prevents any open files below this one from being pushed
    // into the top of the viewport due to the size change of the parent.
    // istanbul ignore next
    if (!this.d_is_open && top < 0) {
      this.$nextTick(() => {
        this.$el.scrollIntoView();
      });
    }
  }
}
