



















































































































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import Draggable from 'vuedraggable';

import { AGTestCase, AGTestCommand, AGTestSuite } from 'ag-client-typescript';

import APIErrors from '@/components/api_errors.vue';
import ContextMenu from '@/components/context_menu/context_menu.vue';
import ContextMenuItem from '@/components/context_menu/context_menu_item.vue';
import Modal from '@/components/modal.vue';
import AGTestCaseSettings from '@/components/project_admin/ag_tests/ag_test_case_settings.vue';
import ValidatedForm from '@/components/validated_form.vue';
import ValidatedInput, { ValidatorResponse } from '@/components/validated_input.vue';
import {
  handle_api_errors_async,
  handle_global_errors_async,
  make_error_handler_func
} from '@/error_handling';
import { toggle } from '@/utils';
import { is_not_empty } from '@/validators';

@Component({
  components: {
    APIErrors,
    AGTestCaseSettings,
    ContextMenu,
    ContextMenuItem,
    Draggable,
    Modal,
    ValidatedForm,
    ValidatedInput
  }
})
export default class AGTestCasePanel extends Vue {

  @Prop({required: true, type: AGTestCase})
  ag_test_case!: AGTestCase;

  @Prop({required: true, type: AGTestSuite})
  ag_test_suite!: AGTestSuite;

  @Prop({required: false, type: AGTestCommand})
  active_ag_test_command!: AGTestCommand | null;

  readonly is_not_empty = is_not_empty;

  d_add_command_form_is_valid = false;
  d_clone_case_form_is_valid = true;
  d_adding_command = false;
  d_cloning = false;
  d_deleting = false;
  d_show_ag_test_case_settings_modal = false;
  d_show_new_ag_test_command_modal = false;
  d_show_clone_ag_test_case_modal = false;
  d_show_delete_ag_test_case_modal = false;
  d_new_command_name = "";
  d_new_command = "";
  d_cloned_case_name: string = "";

  get commands_are_visible() {
    return this.d_commands_are_visible;
  }
  private d_commands_are_visible = false;

  @Watch('active_ag_test_command')
  on_active_ag_test_command_changed(new_active_ag_test_command: AGTestCommand,
                                    old_active_ag_test_command: AGTestCommand) {
    if (this.active_ag_test_command !== null
        && new_active_ag_test_command.ag_test_case === this.ag_test_case.pk) {
      this.d_commands_are_visible = true;
    }
  }

  created() {
    if (this.command_in_case_is_active) {
      this.d_commands_are_visible = true;
    }
  }

  get has_multiple_commands() {
    return this.ag_test_case.ag_test_commands.length > 1;
  }

  get command_in_case_is_active() {
    return this.active_ag_test_command !== null
           && this.active_ag_test_command.ag_test_case === this.ag_test_case.pk;
  }

  open_new_ag_test_command_modal() {
    this.d_new_command = "";
    this.d_new_command_name = "";
    this.d_show_new_ag_test_command_modal = true;
    Vue.nextTick(() => {
      (<ValidatedInput> this.$refs.new_ag_test_command_name).focus();
    });
  }

  open_clone_ag_test_case_modal() {
    this.d_cloned_case_name = this.ag_test_case.name;
    this.d_show_clone_ag_test_case_modal = true;
    Vue.nextTick(() => {
        (<ValidatedInput> this.$refs.ag_test_case_clone_name).focus({select: true});
    });
  }

  get is_open() {
    return this.d_commands_are_visible;
  }

  update_ag_test_case_panel_when_clicked() {
    if (!this.d_commands_are_visible) {
      if (this.command_in_case_is_active) {
        this.d_commands_are_visible = true;
      }
      else {
        this.d_commands_are_visible = true;
        this.$emit('update_active_item', this.ag_test_case);
      }
    }
    else {
      if (!this.command_in_case_is_active) {
        this.$emit('update_active_item', this.ag_test_case);
      }
      else {
        this.d_commands_are_visible = false;
      }
    }
  }

  @handle_api_errors_async(make_error_handler_func('delete_errors'))
  delete_ag_test_case() {
    return toggle(this, 'd_deleting', async () => {
      await this.ag_test_case.delete();
      this.d_show_delete_ag_test_case_modal = false;
    });
  }

  @handle_api_errors_async(handle_clone_ag_test_case_error)
  async clone_ag_test_case() {
    try {
      this.d_cloning = true;
      await this.ag_test_case.copy(this.d_cloned_case_name);
      this.d_show_clone_ag_test_case_modal = false;
    }
    finally {
      this.d_cloning = false;
    }
  }

  @handle_global_errors_async
  set_ag_test_command_order() {
    return AGTestCommand.update_order(
      this.ag_test_case.pk, this.ag_test_case.ag_test_commands.map(cmd => cmd.pk));
  }

  @handle_api_errors_async(handle_add_ag_test_command_error)
  async add_ag_test_command() {
    try {
      this.d_adding_command = true;
      await AGTestCommand.create(
        this.ag_test_case!.pk, {name: this.d_new_command_name, cmd: this.d_new_command}
      );
      this.d_show_new_ag_test_command_modal = false;
    }
    finally {
      this.d_adding_command = false;
    }
  }
}

function handle_add_ag_test_command_error(component: AGTestCasePanel, error: unknown) {
  (<APIErrors> component.$refs.new_command_api_errors).show_errors_from_response(error);
}

function handle_clone_ag_test_case_error(component: AGTestCasePanel, error: unknown) {
    (<APIErrors> component.$refs.clone_case_api_errors).show_errors_from_response(error);
}

