import { render, staticRenderFns } from "./view_file_demo.vue?vue&type=template&id=2a9fc865&scoped=true&"
import script from "./view_file_demo.vue?vue&type=script&lang=ts&"
export * from "./view_file_demo.vue?vue&type=script&lang=ts&"
import style0 from "./view_file_demo.vue?vue&type=style&index=0&id=2a9fc865&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2a9fc865",
  null
  
)

export default component.exports