










































import { Component, Prop, Vue } from 'vue-property-decorator';

import SelectObject from '@/components/select_object.vue';

@Component({
  components: {
    SelectObject
  }
})
export default class SelectObjectDemo extends Vue {
  things = [
    {id: 1, name: 'Thing 1'},
    {id: 4, name: 'Thing 2'},
    {id: 2, name: 'Thing 3'},
    {id: 3, name: 'Thing 4'},
  ];

  selected = this.things[2];
  initially_null = null;
  recent_selection = null;
}
