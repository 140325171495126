



























































































import { Component, Prop, Vue } from 'vue-property-decorator';

import Dropdown from '@/components/dropdown.vue';

@Component({
  components: { Dropdown }
})
export default class DropdownDemo extends Vue {

  some_word = "";

  menu_items = [
    { option: "1 Passenger" },
    { option: "2 Passengers" },
    { option: "3 Passengers" },
    { option: "4 Passengers" },
    { option: "5 Passengers" },
    { option: "6 Passengers" },
    { option: "7 Passengers" },
    { option: "8 Passengers" },
    { option: "9 Passengers" }
  ];

  selected_menu_item = { option: "2 Passengers" };

  names = [
    { first_name: "Elora", last_name: "Blue"},
    { first_name: "Brittany", last_name: "Cost"},
    { first_name: "Sam", last_name: "Sanchez"},
    { first_name: "Jordan", last_name: "Johnson"},
    { first_name: "Michelle", last_name: "Brandt"}
  ];

  food_menu_items = [
    "Cheeseburger",
    "Fries",
    "Shake",
    "Malt",
    "Hamburger",
    "Chicken Sandwich"
  ];

  contact_methods = [{contact: "By Phone"}, {contact: "By Email"}];

  add_item_1(item: {option: string}) {
    this.selected_menu_item = item;
    this.chosen_items_1.push(item);
  }

  add_item_2(item: string) {
    this.chosen_items_2.push(item);
  }

  add_item_3(item: {first_name: string, last_name: string}) {
    this.chosen_items_3.push(item);
  }

  chosen_items_1: object[] = [];
  chosen_items_2: string[] = [];
  chosen_items_3: object[] = [];

}
