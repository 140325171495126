var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.d_mutation_test_suite !== null)?_c('div',{attrs:{"id":"buggy-implementations-component"}},[_c('div',{staticClass:"form-field-wrapper"},[_c('label',{staticClass:"label"},[_vm._v(" Points per exposed bug ")]),_c('validated-input',{ref:"points_per_exposed_bug",attrs:{"id":"points-per-exposed-bug","validators":[
                         _vm.is_not_empty,
                         _vm.is_number,
                         _vm.is_non_negative,
                         _vm.has_less_than_or_equal_to_four_digits,
                         _vm.has_less_than_or_equal_to_two_decimal_places
                       ],"input_style":"max-width: 300px; width: 100%"},on:{"input":function($event){return _vm.$emit('input', _vm.d_mutation_test_suite)}},model:{value:(_vm.d_mutation_test_suite.points_per_exposed_bug),callback:function ($$v) {_vm.$set(_vm.d_mutation_test_suite, "points_per_exposed_bug", $$v)},expression:"d_mutation_test_suite.points_per_exposed_bug"}})],1),_c('div',{staticClass:"checkbox-input-container"},[_c('label',{staticClass:"label",attrs:{"id":"override-max-points-label"}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.d_override_max_points),expression:"d_override_max_points"}],staticClass:"checkbox",attrs:{"id":"override-max-points","type":"checkbox"},domProps:{"checked":Array.isArray(_vm.d_override_max_points)?_vm._i(_vm.d_override_max_points,null)>-1:(_vm.d_override_max_points)},on:{"change":[function($event){var $$a=_vm.d_override_max_points,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.d_override_max_points=$$a.concat([$$v]))}else{$$i>-1&&(_vm.d_override_max_points=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.d_override_max_points=$$c}},_vm.toggle_override_max_points]}}),_vm._v(" Override max points ")])]),(_vm.d_override_max_points)?_c('div',{staticClass:"form-field-wrapper"},[_c('label',{staticClass:"label"},[_vm._v(" Max points ")]),_c('validated-input',{ref:"max_points",attrs:{"id":"max-points","validators":[
                         _vm.is_not_empty,
                         _vm.is_integer,
                         _vm.is_non_negative
                       ],"from_string_fn":_vm.string_to_num,"input_style":"max-width: 300px; width: 100%"},on:{"input":function($event){return _vm.$emit('input', _vm.d_mutation_test_suite)}},model:{value:(_vm.d_mutation_test_suite.max_points),callback:function ($$v) {_vm.$set(_vm.d_mutation_test_suite, "max_points", $$v)},expression:"d_mutation_test_suite.max_points"}})],1):_vm._e(),_c('div',{staticClass:"form-field-wrapper"},[_c('label',{staticClass:"label"},[_vm._v(" Max num student tests ")]),_c('validated-input',{ref:"max_num_student_tests",attrs:{"id":"max-num-student-tests","validators":[
                         _vm.is_not_empty,
                         _vm.is_integer,
                         _vm.is_non_negative
                       ],"from_string_fn":_vm.string_to_num,"input_style":"max-width: 300px; width: 100%"},on:{"input":function($event){return _vm.$emit('input', _vm.d_mutation_test_suite)}},model:{value:(_vm.d_mutation_test_suite.max_num_student_tests),callback:function ($$v) {_vm.$set(_vm.d_mutation_test_suite, "max_num_student_tests", $$v)},expression:"d_mutation_test_suite.max_num_student_tests"}})],1),_c('div',{staticClass:"form-field-wrapper"},[_c('label',{staticClass:"label"},[_vm._v(" Buggy Implementation Names ")]),_c('div',{staticClass:"buggy-implementation-names-input-container"},[_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.d_buggy_impl_names_text),expression:"d_buggy_impl_names_text"}],ref:"buggy_impl_names_input",staticClass:"input",domProps:{"value":(_vm.d_buggy_impl_names_text)},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.add_buggy_implementation_names($event)},"input":function($event){if($event.target.composing){ return; }_vm.d_buggy_impl_names_text=$event.target.value}}}),_c('div',[_c('button',{staticClass:"periwinkle-button",attrs:{"id":"add-buggy-impl-names-button"},on:{"click":_vm.add_buggy_implementation_names}},[_vm._v(" Add Names ")])])])]),(_vm.d_mutation_test_suite.buggy_impl_names.length !== 0)?_c('div',{attrs:{"id":"all-buggy-implementation-names"}},_vm._l((_vm.d_mutation_test_suite.buggy_impl_names),function(buggy_name,index){return _c('div',{class:['buggy-implementation-row',
                   {'odd-buggy-implementation-row': index % 2 !== 0}]},[_c('span',{staticClass:"buggy-implementation-name"},[_vm._v(_vm._s(buggy_name))]),_c('div',{staticClass:"remove-buggy-impl-name-container",attrs:{"title":("Remove " + buggy_name)},on:{"click":function($event){return _vm.remove_buggy_implementation_name(index)}}},[_c('i',{staticClass:"fas fa-times remove-buggy-impl-name-icon"})])])}),0):_vm._e()]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }