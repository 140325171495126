import { render, staticRenderFns } from "./handgrading_container.vue?vue&type=template&id=22eb9a41&scoped=true&"
import script from "./handgrading_container.vue?vue&type=script&lang=ts&"
export * from "./handgrading_container.vue?vue&type=script&lang=ts&"
import style0 from "./handgrading_container.vue?vue&type=style&index=0&id=22eb9a41&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "22eb9a41",
  null
  
)

export default component.exports