var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.show_correctness_fieldset)?_c('fieldset',{ref:"correctness",staticClass:"fieldset"},[_c('legend',{staticClass:"legend"},[_vm._v(" Correctness ")]),(_vm.ag_test_command_result.return_code_correct !== null
                || _vm.ag_test_command_result.timed_out)?_c('div',{ref:"return_code_correctness",staticClass:"feedback-row"},[_c('div',{staticClass:"feedback-label"},[_vm._v("Exit status:")]),_c('div',{staticClass:"feedback"},[_c('div',{staticClass:"correctness-output"},[(_vm.ag_test_command_result.timed_out !== null
                      && this.ag_test_command_result.timed_out)?_c('span',[_c('i',{staticClass:"far fa-clock timed-out-icon"}),_c('span',{staticClass:"timed-out-msg"},[_vm._v(" (Timed out) ")])]):(_vm.ag_test_command_result.return_code_correct !== null)?_c('span',[(_vm.ag_test_command_result.return_code_correct)?_c('i',{staticClass:"fas fa-check correct-icon"}):_c('i',{staticClass:"fas fa-times incorrect-icon"})]):_vm._e()])])]):_vm._e(),(_vm.ag_test_command_result.actual_return_code !== null)?_c('div',{ref:"actual_return_code",staticClass:"feedback-row"},[_c('div',{staticClass:"feedback-label"},[_vm._v("Actual exit status:")]),_c('div',{staticClass:"feedback"},[_c('div',{class:['short-output',
                      {'actual-return-code-correct': _vm.ag_test_command_result !== null
                        && _vm.ag_test_command_result.return_code_correct,
                        'actual-return-code-incorrect': _vm.ag_test_command_result !== null
                        && !_vm.ag_test_command_result.return_code_correct}]},[_vm._v(_vm._s(_vm.ag_test_command_result.actual_return_code))])])]):_vm._e(),(_vm.ag_test_command_result.expected_return_code !== null
               && _vm.ag_test_command_result.expected_return_code !== _vm.ExpectedReturnCode.none)?_c('div',{ref:"expected_return_code",staticClass:"feedback-row"},[_c('div',{staticClass:"feedback-label"},[_vm._v(" Expected exit status: ")]),_c('div',{staticClass:"feedback"},[_c('div',{staticClass:"short-output"},[_vm._v(" "+_vm._s(_vm.ag_test_command_result.expected_return_code === _vm.ExpectedReturnCode.zero ? 0 : 'nonzero')+" ")])])]):_vm._e(),(_vm.ag_test_command_result.stdout_correct !== null)?_c('div',{ref:"stdout_correctness",staticClass:"feedback-row"},[_c('div',{staticClass:"feedback-label"},[_vm._v(" Output: ")]),_c('div',{staticClass:"feedback"},[_c('div',{staticClass:"correctness-output"},[(_vm.ag_test_command_result.stdout_correct)?_c('span',[_c('i',{staticClass:"fas fa-check correct-icon"})]):_c('span',[_c('i',{staticClass:"fas fa-times incorrect-icon"})])])])]):_vm._e(),(_vm.ag_test_command_result.stderr_correct !== null)?_c('div',{ref:"stderr_correctness",staticClass:"feedback-row"},[_c('div',{staticClass:"feedback-label"},[_vm._v(" Error output: ")]),_c('div',{staticClass:"feedback"},[_c('div',{staticClass:"correctness-output"},[(_vm.ag_test_command_result.stderr_correct)?_c('span',[_c('i',{staticClass:"fas fa-check correct-icon"})]):_c('span',[_c('i',{staticClass:"fas fa-times incorrect-icon"})])])])]):_vm._e()]):_vm._e(),(_vm.d_output_size !== null
                  && (_vm.d_output_size.stdout_diff_size !== null
                      || _vm.d_output_size.stderr_diff_size !== null))?_c('fieldset',{ref:"diffs",staticClass:"fieldset"},[_c('legend',{staticClass:"legend"},[_vm._v(" Output Diffs ")]),(_vm.d_output_size.stdout_diff_size !== null)?_c('div',{staticClass:"feedback-row"},[(_vm.d_stdout_diff !== null)?_c('div',{staticClass:"diff-container"},[_c('diff',{ref:"stdout_diff",attrs:{"diff_contents":_vm.d_stdout_diff,"diff_max_height":"50vh","progress":_vm.d_stdout_diff_load_progress,"left_header":"Expected Output","right_header":"Student Output"}})],1):_vm._e()]):_vm._e(),(_vm.d_output_size.stderr_diff_size !== null)?_c('div',{staticClass:"feedback-row"},[(_vm.d_stderr_diff !== null)?_c('div',{staticClass:"diff-container"},[_c('diff',{ref:"stderr_diff",attrs:{"diff_contents":_vm.d_stderr_diff,"diff_max_height":"50vh","progress":_vm.d_stderr_diff_load_progress,"left_header":"Expected Error Output","right_header":"Student Error Output"}})],1):_vm._e()]):_vm._e()]):_vm._e(),(_vm.d_output_size !== null
                  && (_vm.d_output_size.stdout_size !== null || _vm.d_output_size.stderr_size !== null))?_c('fieldset',{ref:"actual_output",staticClass:"fieldset"},[_c('legend',{staticClass:"legend"},[_vm._v(" Actual Output ")]),(_vm.d_output_size.stdout_size !== null)?_c('div',{ref:"actual_stdout_section",staticClass:"feedback-row"},[_c('div',{staticClass:"feedback-label"},[_vm._v(" Output: ")]),_c('div',{staticClass:"feedback"},[(_vm.d_output_size.stdout_size === 0)?_c('div',{staticClass:"short-output"},[_vm._v("No output")]):(_vm.d_stdout_content !== null)?_c('div',{staticClass:"lengthy-output"},[_c('view-file',{ref:"stdout",attrs:{"file_contents":_vm.d_stdout_content,"view_file_max_height":"50vh","progress":_vm.d_stdout_load_progress}})],1):_vm._e()])]):_vm._e(),(_vm.d_output_size.stderr_size !== null)?_c('div',{ref:"actual_stderr_section",staticClass:"feedback-row"},[_c('div',{staticClass:"feedback-label"},[_vm._v(" Error output: ")]),_c('div',{staticClass:"feedback"},[(_vm.d_output_size.stderr_size === 0)?_c('div',{staticClass:"short-output"},[_vm._v("No output")]):(_vm.d_stderr_content !== null)?_c('div',{staticClass:"lengthy-output"},[_c('view-file',{ref:"stderr",attrs:{"file_contents":_vm.d_stderr_content,"view_file_max_height":"50vh","progress":_vm.d_stderr_load_progress}})],1):_vm._e()])]):_vm._e()]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }