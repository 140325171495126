
































import { Component, Inject, Vue } from 'vue-property-decorator';

import {
  Course,
  Project,
} from 'ag-client-typescript';

import { GlobalData } from '@/app.vue';
import { handle_global_errors_async } from '@/error_handling';

@Component
export default class CourseView extends Vue {
  @Inject({from: 'globals'})
  globals!: GlobalData;
  d_globals = this.globals;

  private d_course: Course | null = null;
  d_loading = true;
  private d_projects: Project[] = [];

  @handle_global_errors_async
  async created() {
    this.d_course = await Course.get_by_pk(Number(this.$route.params.course_id));
    this.d_projects = await Project.get_all_from_course(this.d_course.pk);

    await this.d_globals.set_current_course(this.d_course);
    this.d_loading = false;
  }
}
