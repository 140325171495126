import { render, staticRenderFns } from "./edit_late_days.vue?vue&type=template&id=35f879ba&scoped=true&"
import script from "./edit_late_days.vue?vue&type=script&lang=ts&"
export * from "./edit_late_days.vue?vue&type=script&lang=ts&"
import style0 from "./edit_late_days.vue?vue&type=style&index=0&id=35f879ba&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "35f879ba",
  null
  
)

export default component.exports