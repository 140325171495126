








import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

import ContextMenu from '@/components/context_menu/context_menu.vue';

@Component
export default class ContextMenuItem extends Vue {
  @Prop({default: false, type: Boolean})
  disabled!: boolean;

  handle_click(event: Event) {
    if (!this.disabled) {
      this.$emit('click');
    }
  }
}
