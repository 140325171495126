






































  import { Component, Vue } from 'vue-property-decorator';

  import ViewFile from '@/components/view_file.vue';

  @Component({
    components: {ViewFile}
  })
  export default class ViewFileDemo extends Vue {
    height_in = '300px';
    filename_1 = "long_lines.txt";
    file_contents_1 = Promise.resolve("I watch two episodes of family fued back to back every " +
                                      "night. \nThey're supposed to pick the 5 7 or 9 most " +
                                      "probable answers from \na survey \nof 100 people. Like " +
                                      "if the show was called 'hey that's possible' the show \n" +
                                      "would be called Family Feud");
    filename_2 = "many_lines.txt";
    file_contents_2 = Promise.resolve(
`#file-container {
    border: 0px solid lightgray;
    border-radius: 0px 0px 3px 3px;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    width: 100%;
    border-left: 1px solid lightgray;
    border-bottom: 1px solid lightgray;
    border-right: 1px solid lightgray;
}

.continue-bar {
    background-color: white;
    height: 5px;
    width: 45px;
    border-radius: 0px;
}

#current-file {
    background-color: #E8EEFE;
}`);
    filename_3 = "Ice_Cream.cpp";
    file_contents_3 = Promise.resolve("Mint Chocolate Chip");
    alternate_file_contents_3 = Promise.resolve("Blue Moon");

    change_contents() {
      this.file_contents_3 = this.alternate_file_contents_3;
    }
  }

