


















import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class Modal extends Vue {
  @Prop({default: false, type: Boolean})
  click_outside_to_close!: boolean;

  @Prop({default: true, type: Boolean})
  include_closing_x!: boolean;

  @Prop({default: 'large', type: String})
  size!: string;

  @Prop({type: String})
  custom_width!: string;
}
