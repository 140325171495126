



































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

import Tooltip from '@/components/tooltip.vue';
import ValidatedInput from '@/components/validated_input.vue';
import {
  MAX_COMMAND_TIMEOUT,
  MAX_PROCESS_LIMIT,
  MAX_STACK_SIZE_LIMIT,
} from '@/constants';
import {
  is_integer,
  is_not_empty,
  make_max_value_validator,
  make_min_value_validator,
  string_to_num,
} from '@/validators';

class ResourceLimits {
  time_limit: number;
  use_virtual_memory_limit: boolean;
  virtual_memory_limit: number;
  block_process_spawn: boolean;

  constructor(args: ResourceLimits) {
    this.time_limit = args.time_limit;
    this.use_virtual_memory_limit = args.use_virtual_memory_limit;
    this.virtual_memory_limit = args.virtual_memory_limit;
    this.block_process_spawn = args.block_process_spawn;
  }
}

@Component({
  components: {
    Tooltip,
    ValidatedInput,
  }
})
export default class ResourceLimitSettings extends Vue {
  @Prop({required: true})
  resource_limits!: ResourceLimits;

  d_resource_limits: ResourceLimits | null = null;

  readonly is_not_empty = is_not_empty;
  readonly is_integer = is_integer;
  readonly is_greater_than_or_equal_to_zero = make_min_value_validator(0);
  readonly is_greater_than_or_equal_to_one = make_min_value_validator(1);
  readonly is_less_than_or_equal_to_zero = make_max_value_validator(0);
  readonly string_to_num = string_to_num;

  readonly max_command_timeout_validator = make_max_value_validator(MAX_COMMAND_TIMEOUT);

  created() {
    this.d_resource_limits = new ResourceLimits(this.resource_limits);
  }

  @Watch('resource_limits', {deep: true})
  on_suite_changed(new_value: ResourceLimits, old_value: ResourceLimits) {
    this.d_resource_limits = new ResourceLimits(new_value);
  }
}
