var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticStyle:{"padding":"10px"}},[_c('hr'),_c('h1',[_vm._v("Validated Form")]),_c('validated-form',{ref:"form1",on:{"submit":_vm.save_data,"form_validity_changed":function($event){_vm.d_form_is_valid = $event}}},[_c('h3',[_vm._v("Validated Input 1")]),_c('p',[_vm._v("Has to be a number")]),_c('validated-input',{attrs:{"validators":[_vm.is_number],"from_string_fn":function (val) { return parseInt(val, 10); }},model:{value:(_vm.d_data.id),callback:function ($$v) {_vm.$set(_vm.d_data, "id", $$v)},expression:"d_data.id"}}),_c('h3',[_vm._v("Validated Input 2")]),_c('p',[_vm._v("Has to be the string \"mars\"")]),_c('validated-input',{attrs:{"validators":[function (val) {
                                                        return {
                                                          is_valid: val === 'mars',
                                                          error_msg: 'not mars'
                                                        }
                                                       }]},model:{value:(_vm.d_data.name),callback:function ($$v) {_vm.$set(_vm.d_data, "name", $$v)},expression:"d_data.name"}}),_c('h3',[_vm._v("Toggleable Validated Input")]),_c('p',[_vm._v(" If you make this input invalid (not a number) and then toggle it away, it won't count towards the form's validity. "),_c('br'),_vm._v(" Note: When the input comes back, it should have its previously valid value still. ")]),_c('toggle',{model:{value:(_vm.show_toggleable_input),callback:function ($$v) {_vm.show_toggleable_input=$$v},expression:"show_toggleable_input"}},[_c('div',{attrs:{"slot":"on"},slot:"on"},[_vm._v("Show")]),_c('div',{attrs:{"slot":"off"},slot:"off"},[_vm._v("Hide")])]),(_vm.show_toggleable_input)?_c('validated-input',{attrs:{"validators":[_vm.is_number],"from_string_fn":function (val) { return parseInt(val, 10); }},model:{value:(_vm.toggleable_input_value),callback:function ($$v) {_vm.toggleable_input_value=$$v},expression:"toggleable_input_value"}}):_vm._e(),_c('p',[_vm._v(" The save button below is disabled (red) if any of the above inputs are invalid. Otherwise, it is enabled (blue). ")]),_c('button',{class:{
              disabled: !_vm.d_form_is_valid,
              enabled: _vm.d_form_is_valid
            },attrs:{"type":"submit","disabled":!_vm.d_form_is_valid}},[_vm._v("Save")]),_c('h3',[_vm._v("Validated Form is_valid status: "+_vm._s(_vm.d_form_is_valid))]),_c('br'),_c('br')],1),_c('h2',[_vm._v("Errors display on submit")]),_c('validated-form',[_c('p',[_vm._v(" Sometimes you'll have form inputs that start out invalid. Warnings for those fields will be enabled when the user changes the data or tries to submit the form. ")]),_c('validated-input',{attrs:{"validators":[_vm.is_not_empty]},model:{value:(_vm.d_empty_val),callback:function ($$v) {_vm.d_empty_val=$$v},expression:"d_empty_val"}}),_c('button',{staticClass:"enabled",attrs:{"type":"submit"}},[_vm._v("Submit")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }