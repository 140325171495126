




































































































import { Component, Inject, Vue } from 'vue-property-decorator';

import { Course, CourseObserver } from 'ag-client-typescript';

import { GlobalData } from '@/app.vue';
import CourseSettings from '@/components/course_admin/course_settings.vue';
import EditLateDays from '@/components/course_admin/edit_late_days.vue';
import ManageProjects from '@/components/course_admin/manage_projects/manage_projects.vue';
import AdminRoster from '@/components/course_admin/roster/admin_roster.vue';
import HandgraderRoster from '@/components/course_admin/roster/handgrader_roster.vue';
import StaffRoster from '@/components/course_admin/roster/staff_roster.vue';
import StudentRoster from '@/components/course_admin/roster/student_roster.vue';
import Dropdown from '@/components/dropdown.vue';
import SandboxImages from '@/components/sandbox_images/sandbox_images.vue';
import { CurrentTabMixin } from '@/current_tab_mixin';
import { handle_global_errors_async } from '@/error_handling';
import { BeforeDestroy, Mounted } from '@/lifecycle';
import { get_query_param, safe_assign } from "@/utils";

@Component({
  components: {
    AdminRoster,
    HandgraderRoster,
    StaffRoster,
    StudentRoster,
    ManageProjects,
    EditLateDays,
    SandboxImages,
    CourseSettings,
    Dropdown,
  }
})
export default class CourseAdmin extends CurrentTabMixin implements CourseObserver,
                                                                    Mounted,
                                                                    BeforeDestroy {
  @Inject({from: 'globals'})
  globals!: GlobalData;

  d_loading = true;
  role_selected = "";
  roles = [
    RosterChoice.admin,
    RosterChoice.staff,
    RosterChoice.student,
    RosterChoice.handgrader
  ];
  d_course: Course | null = null;

  readonly RosterChoice = RosterChoice;

  @handle_global_errors_async
  async mounted() {
    this.d_course = await Course.get_by_pk(Number(this.$route.params.course_id));
    await this.globals.set_current_course(this.d_course);

    let requested_tab = get_query_param(this.$route.query, "current_tab");
    if (requested_tab === 'late_days' && this.d_course!.num_late_days === 0) {
      this.set_current_tab('settings');
    }
    else {
      this.initialize_current_tab('settings');
    }

    let query_val = get_query_param(this.$route.query, 'current_tab');
    if (query_val === RosterChoice.admin + '_roster') {
      this.role_selected = RosterChoice.admin;
    }
    else if (query_val === RosterChoice.staff + '_roster') {
      this.role_selected = RosterChoice.staff;
    }
    else if (query_val === RosterChoice.student + '_roster') {
      this.role_selected = RosterChoice.student;
    }
    else if (query_val === RosterChoice.handgrader + '_roster') {
      this.role_selected = RosterChoice.handgrader;
    }

    this.d_loading = false;
    Course.subscribe(this);
  }

  beforeDestroy() {
    Course.unsubscribe(this);
  }

  update_course_created(course: Course): void {
  }

  update_course_changed(course: Course): void {
    if (this.d_course !== null && course.pk === this.d_course.pk) {
      safe_assign(this.d_course, course);
    }
  }
}

export enum RosterChoice {
  admin = "Admin",
  staff = "Staff",
  student = "Student",
  handgrader = "Handgrader"
}
