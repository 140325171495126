
















import { Component, Prop, Vue } from 'vue-property-decorator';

import { ExpectedStudentFile, NewExpectedStudentFileData, Project } from 'ag-client-typescript';

import APIErrors from '@/components/api_errors.vue';
import ExpectedStudentFileForm from '@/components/project_admin/expected_student_files/expected_student_file_form.vue';
import { handle_api_errors_async } from '@/error_handling';

@Component({
  components: {
    APIErrors,
    ExpectedStudentFileForm
  }
})
export default class CreateExpectedStudentFile extends Vue {

  @Prop({required: true, type: Project})
  project!: Project;

  d_create_pending = false;
  pattern_is_valid = false;

  @handle_api_errors_async(handle_add_expected_student_file_error)
  async create_expected_student_file(
    new_expected_student_file_data: NewExpectedStudentFileData) {
    try {
      this.d_create_pending = true;
      (<APIErrors> this.$refs.api_errors).clear();
      await ExpectedStudentFile.create(this.project.pk, new_expected_student_file_data);
      (<ExpectedStudentFileForm> this.$refs.form).reset();
    }
    finally {
      this.d_create_pending = false;
    }
  }
}

export function handle_add_expected_student_file_error(component: CreateExpectedStudentFile,
                                                       error: unknown) {
  (<APIErrors> component.$refs.api_errors).show_errors_from_response(error);
}
