







































































































import { Component, Vue } from 'vue-property-decorator';

import ContextMenu from '@/components/context_menu/context_menu.vue';
import ContextMenuItem from '@/components/context_menu/context_menu_item.vue';

@Component({
  components: {ContextMenu, ContextMenuItem}
})
export default class ContextMenuDemo extends Vue {
  menu_1_is_open = false;
  menu_1_coordinates = {x: 0, y: 0};

  menu_2_is_open = false;
  menu_2_coordinates = {x: 0, y: 0};

  empty_menu_is_open = false;
  empty_menu_coordinates = {x: 0, y: 0};

  choice_alert(word: string) {
    alert('You have clicked on: ' + word);
  }

  change_color(word: string) {
    let text = <HTMLElement> this.$el.getElementsByClassName('area-of-focus-1')[0];
    text.style.color = word;
  }

  items = [
    {name: 'Option 1', disabled: false},
    {name: 'Option 2', disabled: false},
    {name: 'Option 3', disabled: false},
    {name: 'Option 4', disabled: true}
  ];

}
