


























































import { Component, Vue } from 'vue-property-decorator';

import Toggle from '@/components/toggle.vue';

@Component({
  components: {
    Toggle
  }
})
export default class ButtonDemo extends Vue {
  buttons_disabled = false;
}
