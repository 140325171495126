import { render, staticRenderFns } from "./dropdown.vue?vue&type=template&id=1bc12388&scoped=true&"
import script from "./dropdown.vue?vue&type=script&lang=ts&"
export * from "./dropdown.vue?vue&type=script&lang=ts&"
import style0 from "./dropdown.vue?vue&type=style&index=0&id=1bc12388&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1bc12388",
  null
  
)

export default component.exports