
















































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

import _ from 'lodash';

import Modal from '@/components/modal.vue';

// types for function props
type Comparator = (lhs: unknown, rhs: unknown) => boolean;
type Filter = (item: unknown, filter_text: string) => boolean;

@Component({
  components: {
    Modal
  }
})
export default class BatchSelect extends Vue {
  @Prop({ required: true, type: Array })
  choices!: unknown[];

  @Prop({ type: Array })
  value!: unknown[];

  @Prop({ type: Function })
  are_items_equal!: Comparator;

  @Prop({ type: Function })
  filter_fn!: Filter;

  d_show_batch_select_modal = false;
  d_batch_search_query: string = "";
  d_selected_items: unknown[] = [];

  created() {
    this.d_selected_items = this.value.slice();
  }

  @Watch("value", { deep: true })
  on_value_change(new_value: unknown[], old_value: unknown[]) {
    this.d_selected_items = this.value.slice();
  }

  batch_toggle_select(item: unknown) {
    if (_.some(this.d_selected_items, (el) => this.are_items_equal(el, item))) {
      this.d_selected_items = _.filter(
        this.d_selected_items,
        (el) => !this.are_items_equal(el, item)
      );
    }
    else {
      this.d_selected_items.push(item);
    }
  }

  get batch_filtered_items() {
    return _.isEmpty(this.d_batch_search_query) ?
      this.choices :
      _.filter(
        this.choices,
        (item) => this.filter_fn(item, this.d_batch_search_query)
      );
  }

  // Call when user clicks the "confirm" button.
  confirm_selection() {
    this.d_show_batch_select_modal = false;
    this.$emit("input", this.d_selected_items);
  }
}
