var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"surround-1"},[_c('p',{staticClass:"tabindex-explanation"},[_vm._v(" If the element that the Dropdown component is on is not an element that normally receives focus, you must add the tabindex attribute to that element so that it can receive focus and blur. ")]),_c('dropdown',{ref:"dropdown_menu_ex_1",attrs:{"items":_vm.menu_items,"initial_highlighted_index":5,"dropdown_height":"200px"},on:{"item_selected":function($event){return _vm.add_item_1($event)}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"airplane"},[_c('i',{staticClass:"fas fa-plane"})]),_c('span',[_vm._v(" "+_vm._s(item.option)+" ")])]}}])},[_c('template',{slot:"header"},[_c('p',{staticClass:"header-para",attrs:{"tabindex":"0"}},[_vm._v(" "+_vm._s(_vm.selected_menu_item.option)+" ")])])],2)],1),_vm._l((_vm.chosen_items_1),function(item){return _c('p',[_vm._v(" "+_vm._s(item.option))])}),_c('div',{staticClass:"surround-2"},[_c('dropdown',{ref:"dropdown_menu_ex_2",attrs:{"items":_vm.names},on:{"item_selected":function($event){return _vm.add_item_2($event)}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var item = ref.item;
return _c('div',{},[_c('div',{staticClass:"row-content-2"},[_c('span',[_vm._v(" "+_vm._s(item.first_name)+" ")])])])}}])},[_c('template',{slot:"header"},[_c('button',{staticClass:"header-button"},[_vm._v(" Menu On A Button ")])])],2)],1),_vm._l((_vm.chosen_items_2),function(item){return _c('p',[_vm._v(" "+_vm._s(item.last_name)+", "+_vm._s(item.first_name))])}),_c('div',{staticClass:"surround-3"},[_c('p',[_vm._v(" "+_vm._s(_vm.some_word))]),_c('dropdown',{ref:"dropdown_menu_ex_3",attrs:{"items":_vm.names},on:{"item_selected":function($event){return _vm.add_item_3($event)}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var item = ref.item;
return _c('div',{},[_c('span',[_vm._v(" "+_vm._s(item.first_name)+" ")])])}}])},[_c('template',{slot:"header"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.some_word),expression:"some_word"}],attrs:{"type":"text","name":"some_word_stuff"},domProps:{"value":(_vm.some_word)},on:{"input":function($event){if($event.target.composing){ return; }_vm.some_word=$event.target.value}}})])],2)],1),_vm._l((_vm.chosen_items_3),function(item){return _c('p',[_vm._v(" "+_vm._s(item.last_name)+", "+_vm._s(item.first_name))])}),_c('div',{staticClass:"web-menu"},[_vm._m(0),_c('dropdown',{ref:"dropdown_menu_ex_4",attrs:{"items":_vm.food_menu_items}},[_c('template',{slot:"header"},[_c('div',{staticClass:"menu-option navy-tile",attrs:{"tabindex":"0"}},[_c('p',[_vm._v(" Products ")])])])],2),_c('dropdown',{ref:"dropdown_menu_ex_5",attrs:{"items":_vm.contact_methods},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var item = ref.item;
return [_c('p',{staticClass:"contact"},[_vm._v(" "+_vm._s(item.contact))])]}}])},[_c('template',{slot:"header"},[_c('div',{staticClass:"menu-option",attrs:{"tabindex":"0"}},[_c('p',[_vm._v(" Contact ")])])])],2)],1)],2)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"menu-option"},[_c('p',[_vm._v(" Home ")])])}]

export { render, staticRenderFns }