









































































































































































































import { CreateElement } from 'vue';
import { Component, Prop, Vue } from 'vue-property-decorator';

import {
  BuildImageStatus,
  BuildSandboxDockerImageTask,
  Course,
  ID,
  SandboxDockerImage,
} from 'ag-client-typescript';

import APIErrors from '@/components/api_errors.vue';
import Collapsible from '@/components/collapsible.vue';
import FileUpload from '@/components/file_upload.vue';
import Modal from '@/components/modal.vue';
import ValidatedForm from '@/components/validated_form.vue';
import ValidatedInput from '@/components/validated_input.vue';
import { handle_api_errors_async, handle_global_errors_async, make_error_handler_func } from '@/error_handling';
import { Poller } from '@/poller';
import { SafeMap } from '@/safe_map' ;
import { assert_not_null, deep_copy, format_datetime_short, safe_assign, toggle } from '@/utils';
import { is_not_empty } from '@/validators';

import BuildImageStatusIcon from './build_image_status_icon.vue';
import BuildImageTaskDetail from './build_image_task_detail.vue';
import BuildSandboxImage from './build_sandbox_image.vue';

interface BuildTasksForImage {
  image: SandboxDockerImage;
  build_tasks: BuildSandboxDockerImageTask[];
}

@Component({
  components: {
    APIErrors,
    BuildSandboxImage,
    BuildImageStatusIcon,
    BuildImageTaskDetail,
    Collapsible,
    FileUpload,
    Modal,
    ValidatedForm,
    ValidatedInput,
  }
})
export default class SandboxImages extends Vue {
  @Prop({default: null, type: Course})
  course!: Course;

  d_loading = true;
  d_sandbox_images: SandboxDockerImage[] = [];
  d_build_tasks: BuildSandboxDockerImageTask[] = [];

  private d_selected_image_pk: number | null = null;
  private d_selected_build_task_pk: number | null = null;

  image_poller: Poller | null = null;

  d_sidebar_collapsed = false;

  d_starting_build = false;

  readonly format_datetime_short = format_datetime_short;

  d_edited_image_name = '';
  d_saving_image_name = false;
  d_image_name_is_valid = false;

  readonly is_not_empty = is_not_empty;

  d_show_delete_image_modal = false;
  d_deleting = false;

  d_loading_images_and_tasks = false;

  async created() {
    await this.load_images_and_build_tasks();
    this.d_loading = false;

    this.image_poller = new Poller(() => this.load_images_and_build_tasks(), 30);
    // tslint:disable-next-line no-floating-promises
    this.image_poller.start_after_delay();
  }

  beforeDestroy() {
    if (this.image_poller !== null) {
      this.image_poller.stop();
    }
  }

  @handle_global_errors_async
  async load_images_and_build_tasks() {
    return toggle(this, 'd_loading_images_and_tasks', async () => {
      [this.d_sandbox_images, this.d_build_tasks] = await Promise.all([
        await SandboxDockerImage.get_images(this.course?.pk ?? null),
        await BuildSandboxDockerImageTask.get_build_tasks(this.course?.pk ?? null)
      ]);
    });
  }

  // Returns an array of pairs. The first item in each pair is a
  // SandboxDockerImage, and the second item is an array
  // of BuildSandboxDockerImageTasks associated with that image.
  get completed_tasks_by_image(): BuildTasksForImage[] {
    assert_not_null(this.d_sandbox_images);
    assert_not_null(this.d_build_tasks);
    let tasks_by_image_pk = new SafeMap<ID, BuildSandboxDockerImageTask[]>();

    for (let task of this.d_build_tasks) {
      if (task.image === null) {
        continue;
      }
      tasks_by_image_pk.get(task.image.pk, [], true).push(task);
    }

    let result: BuildTasksForImage[] = [];
    for (let image of this.d_sandbox_images) {
      result.push({image: image, build_tasks: tasks_by_image_pk.get(image.pk, [])});
    }

    return result;
  }

  get in_progress_tasks() {
    return this.d_build_tasks.filter(task => {
      return (task.status === BuildImageStatus.queued
              || task.status === BuildImageStatus.in_progress);
    });
  }

  get full_build_history() {
    return this.d_build_tasks.sort((first, second) => second.pk - first.pk);
  }

  show_new_image_build() {
    this.d_selected_image_pk = null;
    this.d_selected_build_task_pk = null;
  }

  select_image(image: SandboxDockerImage) {
    this.d_selected_image_pk = image.pk;
    this.d_selected_build_task_pk = null;
    this.d_edited_image_name = image.display_name;
  }

  select_build_task(build_task: BuildSandboxDockerImageTask) {
    this.d_selected_build_task_pk = build_task.pk;
    this.d_selected_image_pk = null;
  }

  get selected_image() {
    if (this.d_selected_image_pk === null) {
      return null;
    }

    let image = this.d_sandbox_images.find(item => item.pk === this.d_selected_image_pk);
    assert_not_null(image);
    return image;
  }

  get selected_build_task() {
    if (this.d_selected_build_task_pk === null) {
      return null;
    }

    let task = this.d_build_tasks.find(item => item.pk === this.d_selected_build_task_pk);
    assert_not_null(task);
    return task;
  }

  @handle_api_errors_async(make_error_handler_func())
  save_selected_image_name() {
    assert_not_null(this.selected_image);
    let to_update = deep_copy(this.selected_image, SandboxDockerImage);
    to_update.display_name = this.d_edited_image_name;
    return toggle(this, 'd_saving_image_name', async () => {
      await to_update.save();
      let index = this.d_sandbox_images.findIndex(image => image.pk === this.d_selected_image_pk);
      Vue.set(this.d_sandbox_images, index, to_update);
      this.d_sandbox_images.sort(
        (first, second) => first.display_name.localeCompare(second.display_name));
    });
  }

  @handle_api_errors_async(make_error_handler_func('delete_errors'))
  delete_selected_image() {
    return toggle(this, 'd_deleting', async () => {
      assert_not_null(this.selected_image);
      await this.selected_image.delete();

      this.d_sandbox_images.splice(
        this.d_sandbox_images.findIndex(image => image.pk === this.d_selected_image_pk),
        1
      );
      this.d_build_tasks = this.d_build_tasks.filter(
        build_task => build_task.image?.pk !== this.d_selected_image_pk
      );

      this.d_selected_image_pk = null;
      this.d_show_delete_image_modal = false;
    });
  }
}
