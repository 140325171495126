




































































import { Component, Inject, Vue } from 'vue-property-decorator';

import { AllCourses, Course, CourseObserver, Semester, User } from 'ag-client-typescript';

import { GlobalData } from '@/app.vue';
import APIErrors from '@/components/api_errors.vue';
import CourseForm, { CourseFormData } from '@/components/course_admin/course_form.vue';
import SingleCourse from '@/components/course_list/single_course.vue';
import Modal from '@/components/modal.vue';
import {
  handle_api_errors_async,
  handle_global_errors_async,
  make_error_handler_func,
} from '@/error_handling';
import {
  assert_not_null,
  toggle,
} from '@/utils';


interface Term {
  semester: Semester | null;
  year: number | null;
}

interface TermCourses {
  term: Term;
  course_list: Course[];
}

@Component({
  components: {
    APIErrors,
    CourseForm,
    Modal,
    SingleCourse
  }
})
export default class CourseList extends Vue implements CourseObserver {
  @Inject({from: 'globals'})
  globals!: GlobalData;
  d_globals = this.globals;

  d_all_courses: AllCourses | null = null;
  d_can_create_courses = false;
  d_loading = true;

  d_show_create_course_modal = false;
  d_creating_course = false;
  d_new_course_form_is_valid = false;

  @handle_global_errors_async
  async created() {
    this.d_all_courses = await Course.get_courses_for_user(this.d_globals.current_user!);
    await this.d_globals.set_current_course(null);
    this.d_can_create_courses = await User.current_can_create_courses();
    Course.subscribe(this);
    this.d_loading = false;
  }

  beforeDestroy() {
    Course.unsubscribe(this);
  }

  @handle_api_errors_async(make_error_handler_func('create_course_api_errors'))
  create_course(form_data: CourseFormData) {
    return toggle(this, 'd_creating_course', async () => {
        await Course.create(form_data);
        this.d_show_create_course_modal = false;
    });
  }

  is_admin(course: Course) {
    assert_not_null(this.d_all_courses);
    return this.d_all_courses.courses_is_admin_for.find(
      item => item.pk === course.pk
    ) !== undefined;
  }

  get courses_by_term(): TermCourses[] {
    if (this.d_all_courses === null) {
      // istanbul ignore next
      return [];
    }

    let result: TermCourses[] = [];
    for (let course_list of Object.values(this.d_all_courses)) {
      for (let course of course_list) {
        let current_term: Term = {semester: course.semester, year: course.year};

        let term_courses = result.find(item => terms_equal(item.term, current_term));
        if (term_courses === undefined) {
          term_courses = {term: current_term, course_list: []};
          result.push(term_courses);
        }

        let has_course = term_courses.course_list.find(item => item.pk === course.pk) !== undefined;
        if (!has_course) {
          term_courses.course_list.push(course);
        }
      }
    }

    result.sort(term_descending);
    for (let term_courses of result) {
      term_courses.course_list.sort((course_a: Course, course_b: Course) =>
        course_a.name.localeCompare(course_b.name, undefined, {numeric: true})
      );
    }

    return result;
  }

  update_course_changed(course: Course): void { }

  update_course_created(course: Course): void {
    if (this.d_all_courses !== null) {
      this.d_all_courses.courses_is_admin_for.push(course);
    }
  }
}

function terms_equal(first: Term, second: Term) {
  return first.year === second.year && first.semester === second.semester;
}

function term_descending(term_courses_a: TermCourses, term_courses_b: TermCourses) {
  let semester_ordering = {
    [Semester.winter]: 1,
    [Semester.spring]: 2,
    [Semester.summer]: 3,
    [Semester.fall]: 4
  };

  if (term_courses_a.term.year === term_courses_b.term.year) {
    let semester_a = term_courses_a.term.semester === null
      ? 0 : semester_ordering[term_courses_a.term.semester];
    let semester_b = term_courses_b.term.semester === null
      ? 0 : semester_ordering[term_courses_b.term.semester];
    return semester_b - semester_a;
  }

  let year_a = term_courses_a.term.year === null ? 0 : term_courses_a.term.year;
  let year_b = term_courses_b.term.year === null ? 0 : term_courses_b.term.year;
  return year_b - year_a;
}

