














import { Component, Prop, Vue } from 'vue-property-decorator';

import { ExpectedStudentFile } from 'ag-client-typescript';

@Component
export default class ExpectedStudentFilesList extends Vue {
  @Prop({required: true, type: Array})
  expected_student_files!: ExpectedStudentFile[];

  d_show = false;
}
