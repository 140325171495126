




















































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

import { ExpectedStudentFile } from 'ag-client-typescript';

import APIErrors from '@/components/api_errors.vue';
import Modal from '@/components/modal.vue';
import ExpectedStudentFileForm, { ExpectedStudentFileFormData } from '@/components/project_admin/expected_student_files/expected_student_file_form.vue';
import { handle_api_errors_async, handle_global_errors_async } from '@/error_handling';
import { safe_assign } from '@/utils';

@Component({
  components: {
    APIErrors,
    ExpectedStudentFileForm,
    Modal
  }
})
export default class SingleExpectedStudentFile extends Vue {

  @Prop({required: true, type: Object})
  expected_student_file!: ExpectedStudentFile;

  editing = false;
  d_delete_pending = false;

  // Using a default value here is cleaner than making this field nullable and using
  // the non-null assertion operator at all use points
  d_expected_student_file: ExpectedStudentFile = new ExpectedStudentFile({
    pk: 0,
    project: 0,
    pattern: "",
    min_num_matches: 1,
    max_num_matches: 1,
    last_modified: ""
  });
  d_saving = false;
  pattern_is_valid = false;
  d_show_delete_expected_student_file_modal = false;

  created() {
    this.d_expected_student_file = new ExpectedStudentFile(this.expected_student_file);
  }

  @Watch('expected_student_file')
  on_expected_student_file_changed(new_file: ExpectedStudentFile,
                                   old_file: ExpectedStudentFile) {
    this.d_expected_student_file.pattern = new_file.pattern;
    this.d_expected_student_file.min_num_matches = new_file.min_num_matches;
    this.d_expected_student_file.max_num_matches = new_file.max_num_matches;
  }

  get wildcard_is_present() {
    return this.expected_student_file.pattern.match('[*?![\\]]') !== null;
  }

  @handle_api_errors_async(handle_edit_expected_student_file_error)
  async update_expected_student_file(file: ExpectedStudentFileFormData) {
    try {
      this.d_saving = true;
      (<APIErrors> this.$refs.api_errors).clear();
      safe_assign(this.d_expected_student_file, file);
      await this.d_expected_student_file.save();
      (<ExpectedStudentFileForm> this.$refs.form).reset();
      this.editing = false;
    }
    finally {
      this.d_saving = false;
    }
  }

  cancel_update() {
    (<ExpectedStudentFileForm> this.$refs.form).reset();
    this.editing = false;
  }

  @handle_global_errors_async
  async delete_expected_student_file() {
    try {
      this.d_delete_pending = true;
      await this.expected_student_file.delete();
      this.d_show_delete_expected_student_file_modal = false;
    }
    finally {
      this.d_delete_pending = false;
    }
  }
}

export function handle_edit_expected_student_file_error(component: SingleExpectedStudentFile,
                                                        error: unknown) {
  (<APIErrors> component.$refs.api_errors).show_errors_from_response(error);
}
