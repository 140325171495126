




















import { Component, Prop, Vue } from 'vue-property-decorator';

import * as ag_cli from 'ag-client-typescript';

import { get_handgrading_status, HandgradingStatus } from './handgrading_status';

@Component({})
export default class GroupSummaryPanel extends Vue {
  @Prop({required: true})
  group_summary!: ag_cli.GroupWithHandgradingResultSummary;

  readonly HandgradingStatus = HandgradingStatus;

  get status() {
    let status = get_handgrading_status(this.group_summary);
    if (status === HandgradingStatus.graded) {
      let result = this.group_summary.handgrading_result!;
      return `${result.total_points}/${result.total_points_possible}`;
    }
    return status;
  }
}
