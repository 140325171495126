


































































































import { Component, Prop, Vue } from 'vue-property-decorator';

import { InstructorFile, InstructorFileObserver, Project } from 'ag-client-typescript';

import APIErrors from "@/components/api_errors.vue";
import FileUpload from '@/components/file_upload.vue';
import Modal from '@/components/modal.vue';
import ProgressBar from '@/components/progress_bar.vue';
import ViewFile from '@/components/view_file.vue';
import {
  handle_api_errors_async,
  handle_global_errors_async,
  make_error_handler_func,
} from "@/error_handling";
import { BeforeDestroy, Created } from '@/lifecycle';
import { OpenFilesMixin } from '@/open_files_mixin';
import { SafeMap } from '@/safe_map';
import { toggle } from '@/utils';

import SingleInstructorFile from './single_instructor_file.vue';

@Component({
  components: {
    APIErrors,
    Modal,
    FileUpload,
    ProgressBar,
    SingleInstructorFile,
    ViewFile,
  },
})
export default class InstructorFiles extends OpenFilesMixin implements InstructorFileObserver,
                                                                       Created,
                                                                       BeforeDestroy {
  @Prop({required: true, type: Project})
  project!: Project;

  d_collapsed = false;
  d_uploading = false;
  d_upload_progress: number | null = null;

  // Array of files that will be deleted after the user confirms any kind of
  // deletion (single or batch); allows us to reuse 1 modal for both kinds of
  // deletion
  d_to_be_deleted = new Array<InstructorFile>();
  d_delete_pending = false;
  d_show_delete_modal = false;

  // Array of files selected for deletion in batch mode
  d_batch_to_be_deleted = new Array<InstructorFile>();

  // Toggle a file to be included or excluded for a batch operation
  toggle_file_for_batch_operation(file: InstructorFile, value: boolean) {
    if (value) {
      this.d_batch_to_be_deleted.push(file);
    }
    else {
      this.d_batch_to_be_deleted = this.d_batch_to_be_deleted.filter((f) => f.pk !== file.pk);
    }
  }

  // Called when a user presses the delete button inside of child SingleInstructorFile component
  request_single_delete(file: InstructorFile) {
    this.d_to_be_deleted = [];
    this.d_to_be_deleted.push(file);
    this.d_show_delete_modal = true;
  }

  // Called when a user presses the batch delete button from this component
  request_batch_delete() {
    this.d_to_be_deleted = this.d_batch_to_be_deleted;
    this.d_show_delete_modal = true;
  }

  @handle_api_errors_async(make_error_handler_func("delete_errors"))
  async delete_files_permanently() {
    try {
      this.d_delete_pending = true;

      // Delete all files in parallel
      await Promise.all(
        this.d_to_be_deleted.map(async (file) => {
          await file.delete();
          this.d_batch_to_be_deleted = this.d_batch_to_be_deleted.filter(f => f.pk !== file.pk);
        })
      );

      this.d_show_delete_modal = false;
    }
    finally {
      this.d_delete_pending = false;
      this.d_to_be_deleted = [];
    }
  }


  // Returns whether we are in batch deletion mode
  get in_batch_mode() {
    return this.d_batch_to_be_deleted.length > 0;
  }

  // Do NOT modify the contents of this array!!
  get instructor_files(): ReadonlyArray<Readonly<InstructorFile>> {
    // Since this component is only used in project admin, we know that
    // this.project.instructor files will never be undefined.
    return this.project.instructor_files!;
  }

  created() {
    InstructorFile.subscribe(this);
  }

  beforeDestroy() {
    InstructorFile.unsubscribe(this);
  }

  view_file(file: InstructorFile) {
    this.open_file(file.name, (progress_callback) => file.get_content(progress_callback));
  }

  @handle_api_errors_async(handle_file_upload_errors)
  add_instructor_files(files: File[]) {
    this.d_upload_progress = null;
    (<APIErrors> this.$refs.api_errors).clear();
    return toggle(this, 'd_uploading', async () => {
      for (let file of files) {
        let file_to_update = this.instructor_files.find(item => item.name === file.name);
        if (file_to_update !== undefined) {
          await file_to_update.set_content(file, (event: ProgressEvent) => {
            if (event.lengthComputable) {
              this.d_upload_progress = 100 * (1.0 * event.loaded / event.total);
            }
          });
        }
        else {
          await InstructorFile.create(
            this.project.pk, file.name, file, (event: ProgressEvent) => {
              if (event.lengthComputable) {
                this.d_upload_progress = 100 * (1.0 * event.loaded / event.total);
              }
            }
          );
        }
      }
      this.d_upload_progress = null;
      (<FileUpload> this.$refs.instructor_files_upload).clear_files();
    });
  }

  update_instructor_file_content_changed(instructor_file: InstructorFile, file_content: Blob) {
    if (instructor_file.project === this.project.pk) {
      this.update_file(instructor_file.name, Promise.resolve(file_content));
    }
  }

  update_instructor_file_deleted(instructor_file: InstructorFile) {
    if (instructor_file.project === this.project.pk) {
      this.delete_file(instructor_file.name);
    }
  }

  update_instructor_file_renamed(instructor_file: InstructorFile, old_name: string) {
    if (instructor_file.project === this.project.pk) {
      this.rename_file(old_name, instructor_file.name);
    }
  }

  update_instructor_file_created(instructor_file: InstructorFile) {}
}

function handle_file_upload_errors(component: InstructorFiles, error: unknown) {
  (<APIErrors> component.$refs.api_errors).show_errors_from_response(error);
}
