






























































































































































































































import { Component, Inject, Prop, Vue, Watch } from 'vue-property-decorator';

import {
    Course,
    FeedbackCategory,
    GradingStatus,
    Group,
    Submission,
    SubmissionResultFeedback,
    SubmissionResults,
    SubmissionWithResults,
} from 'ag-client-typescript';
import * as FileSaver from 'file-saver';

import { GlobalData } from '@/app.vue';
import APIErrors from "@/components/api_errors.vue";
import Modal from '@/components/modal.vue';
import ProgressOverlay from '@/components/progress_overlay.vue';
import AGTestSuiteResultDetail from '@/components/project_view/submission_detail/ag_test_suite_result_detail.vue';
import { CorrectnessLevel } from '@/components/project_view/submission_detail/correctness';
import MutationSuiteResults from '@/components/project_view/submission_detail/mutation_suite_results.vue';
import ResultPanel from '@/components/project_view/submission_detail/result_panel.vue';
import ViewFile from '@/components/view_file.vue';
import { SYSADMIN_CONTACT } from '@/constants';
import { handle_api_errors_async, handle_global_errors_async } from '@/error_handling';
import { OpenFilesMixin } from '@/open_files_mixin';
import { format_datetime, toggle } from '@/utils';

@Component({
  components: {
    APIErrors,
    AGTestSuiteResultDetail,
    Modal,
    MutationSuiteResults,
    ProgressOverlay,
    ResultPanel,
    ViewFile,
  }
})
export default class SubmissionDetail extends OpenFilesMixin {

  @Inject({from: 'globals'})
  globals!: GlobalData;
  d_globals = this.globals;

  @Prop({required: true, type: Object})
  submission_with_results!: SubmissionWithResults;

  @Prop({required: true, type: Course})
  course!: Course;

  @Prop({required: true, type: Group})
  group!: Group;

  @Prop({required: true, type: Boolean})
  is_ultimate_submission!: boolean;

  @Watch('submission_with_results')
  on_selected_submission_change(new_value: SubmissionWithResults,
                                old_value: SubmissionWithResults) {
    this.d_submission_fdbk_override = null;
    this.d_fdbk_category_override = null;
    this.close_all_files();
  }

  d_submission_fdbk_override: null | SubmissionResultFeedback = null;
  d_fdbk_category_override: FeedbackCategory | null = null;
  d_show_remove_submission_from_queue_modal = false;
  d_loading_results = false;
  d_removing_from_queue = false;
  d_downloading_file = false;
  d_download_progress: number | null = null;

  readonly CorrectnessLevel = CorrectnessLevel;
  readonly FeedbackCategory = FeedbackCategory;
  readonly GradingStatus = GradingStatus;
  readonly format_datetime = format_datetime;

  readonly SYSADMIN_CONTACT = SYSADMIN_CONTACT;

  get submission() {
    return new Submission(this.submission_with_results);
  }

  get submission_result() {
    return this.d_submission_fdbk_override === null
        ? this.submission_with_results.results : this.d_submission_fdbk_override;
  }

  private get show_score() {
    return (this.submission.status === GradingStatus.waiting_for_deferred
            || this.submission.status === GradingStatus.finished_grading)
           && this.submission_result !== null
           && Number(this.submission_result.total_points_possible) !== 0;
  }

  get feedback_category(): FeedbackCategory {
    if (this.d_fdbk_category_override !== null) {
      return this.d_fdbk_category_override;
    }

    if (this.d_globals.user_roles!.is_staff) {
      if (this.is_group_member
          || (this.is_ultimate_submission && this.d_globals.user_roles!.is_admin)) {
        return FeedbackCategory.max;
      }
      return FeedbackCategory.staff_viewer;
    }
    if (this.is_ultimate_submission) {
      return FeedbackCategory.ultimate_submission;
    }
    if (this.submission.is_past_daily_limit) {
      return FeedbackCategory.past_limit_submission;
    }
    return FeedbackCategory.normal;
  }

  private get show_auto_update_msg() {
    return (this.submission.status === GradingStatus.received
            || this.submission.status === GradingStatus.queued
            || this.submission.status === GradingStatus.being_graded)
            || (this.d_globals.user_roles.is_staff
                && this.submission.status === GradingStatus.waiting_for_deferred);
  }

  @handle_global_errors_async
  private load_adjusted_fdbk(fdbk_category: FeedbackCategory) {
    return toggle(this, 'd_loading_results', async () => {
      this.d_submission_fdbk_override = await SubmissionResults.get_submission_result(
        this.submission.pk, fdbk_category
      );
      this.d_fdbk_category_override = fdbk_category;
    });
  }

  private get does_not_count_for_current_user() {
    return this.submission.does_not_count_for.findIndex(
        username => username === this.d_globals.current_user!.username
    ) !== -1;
  }

  private get is_group_member() {
    return this.group.member_names.findIndex(
        member_name => member_name === this.d_globals.current_user!.username
    ) !== -1;
  }

  private view_file(filename: string) {
    this.open_file(
      filename,
      (progress_callback) => this.submission.get_file_content(filename, progress_callback)
    );
  }

  @handle_global_errors_async
  private download_file(filename: string) {
    this.d_download_progress = null;
    return toggle(this, 'd_downloading_file', async () => {
      let content = this.submission.get_file_content(filename, (event) => {
        if (event.lengthComputable) {
          this.d_download_progress = 100 * (1.0 * event.loaded / event.total);
        }
      });
      FileSaver.saveAs(new File([await content], filename));
    });
  }

  @handle_api_errors_async(handle_remove_submission_from_queue_error)
  private remove_submission_from_queue() {
    return toggle(this, 'd_removing_from_queue', async () => {
      await this.submission.remove_from_queue();
      this.d_show_remove_submission_from_queue_modal = false;
    });
  }
}

export function handle_remove_submission_from_queue_error(component: SubmissionDetail,
                                                          error: unknown) {
  (<APIErrors> component.$refs.api_errors).show_errors_from_response(error);
}
