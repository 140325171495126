

















































































































import { Component, Inject, Prop, Vue, Watch } from 'vue-property-decorator';

import {
  Annotation,
  AppliedAnnotation,
  Comment,
  HandgradingResult,
  HandgradingRubric,
  Location,
  UserRoles,
} from "ag-client-typescript";

import ContextMenu from '@/components/context_menu/context_menu.vue';
import ContextMenuItem from "@/components/context_menu/context_menu_item.vue";
import Modal from '@/components/modal.vue';
import ProgressBar from '@/components/progress_bar.vue';
import { handle_global_errors_async } from '@/error_handling';
import { Created } from '@/lifecycle';
import { SafeMap } from '@/safe_map';
import { chain, toggle } from '@/utils';

import {
  handgrading_comment_factory,
  HandgradingComment,
} from './project_view/handgrading/handgrading_comment';

@Component({
  components: {
    ContextMenu,
    ContextMenuItem,
    Modal,
    ProgressBar,
  }
})
export default class ViewFile extends Vue implements Created {

  @Prop({default: "", type: String})
  filename!: string;

  @Prop({required: true, type: Promise})
  file_contents!: Promise<string>;

  // A number from 0 to 100 that will be displayed as
  // the progress in loading file_contents.
  @Prop({default: null, type: Number})
  progress!: number | null;

  // If the file is larger than this number, the user will be prompted before
  // it's displayed.
  @Prop({default: Math.pow(10, 6), type: Number})
  display_size_threshold!: number;

  @Prop({default: "", type: String})
  view_file_height!: string;

  @Prop({default: "", type: String})
  view_file_max_height!: string;

  d_filename: string = "";
  d_file_contents: string = "";
  d_loading = true;
  d_saving = false;
  d_show_anyway = false;

  readonly num_lines_per_page = 1000;
  d_num_lines_rendered = this.num_lines_per_page;

  // If null, the component will behave normally (no handgrading).
  // When this field is non-null, handgrading functionality will be made available.
  @Prop({default: null, type: HandgradingResult})
  handgrading_result!: HandgradingResult | null;
  // Aliasing handgrading result for reactivity on members of handgrading_result
  d_handgrading_result: HandgradingResult | null = null;

  @Prop({default: false, type: Boolean})
  enable_custom_comments!: boolean;

  // When true, editing handgrading results will be disabled.
  @Prop({default: true, type: Boolean})
  readonly_handgrading_results!: boolean;

  d_hovered_comment: HandgradingComment | null = null;

  d_context_menu_is_open = false;
  d_context_menu_coordinates = {x: 0, y: 0};
  d_show_comment_modal = false;
  d_comment_text = '';

  d_is_highlighting = false;
  d_first_highlighted_line: number | null = null;
  d_last_highlighted_line: number | null = null;

  @handle_global_errors_async
  async created() {
    this.d_handgrading_result = this.handgrading_result;
    this.d_file_contents = await this.file_contents;
    this.d_filename = this.filename;

    this.d_loading = false;
  }

  @Watch('file_contents')
  async on_file_contents_change(new_content: Promise<string>, old_content: string) {
    return this.set_new_file_contents(new_content);
  }

  @handle_global_errors_async
  private set_new_file_contents(new_content: Promise<string>) {
    return toggle(this, 'd_loading', async () => {
      this.d_show_anyway = false;
      this.d_file_contents = await new_content;
    });
  }

  @Watch('filename')
  on_filename_change(new_file_name: string, old_file_name: string) {
    this.d_filename = new_file_name;
  }

  get file_is_large() {
    return this.d_file_contents.length > this.display_size_threshold;
  }

  // IMPORTANT: We want this to be a computed property. Indexing into
  // a large reactive array in the template will significantly increase
  // render times.
  private get split_content() {
    return this.d_file_contents.split('\n');
  }

  private get num_lines_to_show() {
    return Math.min(this.d_num_lines_rendered, this.split_content.length);
  }

  private render_more_lines() {
    this.d_num_lines_rendered = Math.min(
      this.split_content.length,
      this.d_num_lines_rendered + this.num_lines_per_page
    );
  }

  get handgrading_enabled() {
    return this.handgrading_result !== null;
  }

  get handgrading_comments(): SafeMap<number, HandgradingComment[]> {
    if (this.d_handgrading_result === null) {
      return new SafeMap();
    }

    let result =  new SafeMap<number, HandgradingComment[]>();

    let annotations = this.d_handgrading_result.applied_annotations.filter(
      (item) => item.location.filename === this.filename);

    let comments = this.d_handgrading_result.comments.filter(
      (item) => item.location !== null && item.location.filename === this.filename);

    for (let item of chain<AppliedAnnotation | Comment>(annotations, comments)) {
      let handgrading_comment = handgrading_comment_factory(item);
      result.get(
        handgrading_comment.location.last_line, [], true
      ).push(handgrading_comment);
    }

    // Sort lists of comments ending on the same line by first line
    for (let [last_line, comment_list] of result) {
      comment_list.sort(
        (first, second) => first.location.first_line - second.location.first_line);
    }

    return result;
  }

  // Returns true if line_num is contained in any provided handgrading comments.
  line_in_comment(line_num: number) {
    for (let [last_line, comment_list] of this.handgrading_comments) {
      let first_line = comment_list[0].location.first_line;
      if (line_num >= first_line && line_num <= last_line) {
        return true;
      }
    }
    return false;
  }

  start_highlighting(line_index: number) {
    if (this.readonly_handgrading_results
        || !this.handgrading_enabled
        || this.d_is_highlighting
        || this.d_context_menu_is_open
        || this.d_saving) {
      return;
    }

    this.d_is_highlighting = true;
    this.d_first_highlighted_line = line_index;
    this.d_last_highlighted_line = line_index;
  }

  grow_highlighted_region(line_index: number) {
    if (this.readonly_handgrading_results
        || !this.handgrading_enabled
        || !this.d_is_highlighting) {
      return;
    }

    if (line_index < this.d_first_highlighted_line!) {
      this.d_first_highlighted_line = line_index;
    }
    if (line_index > this.d_last_highlighted_line!) {
      this.d_last_highlighted_line = line_index;
    }
  }

  stop_highlighting(event: MouseEvent, line_index: number) {
    if (this.readonly_handgrading_results
        || !this.handgrading_enabled
        || !this.d_is_highlighting) {
      return;
    }

    this.d_is_highlighting = false;
    this.d_context_menu_coordinates = {x: event.pageX, y: event.pageY};
    this.d_context_menu_is_open = true;
  }

  open_comment_modal() {
    this.d_show_comment_modal = true;
    this.$nextTick(() => (<HTMLElement> this.$refs.comment_text).focus());
  }

  @handle_global_errors_async
  apply_annotation(annotation: Annotation) {
    return toggle(this, 'd_saving', async () => {
      await AppliedAnnotation.create(this.d_handgrading_result!.pk, {
        annotation: annotation.pk,
        location: {
          first_line: this.d_first_highlighted_line!,
          last_line: this.d_last_highlighted_line!,
          filename: this.filename,
        }
      });
      this.finish_commenting();
    });
  }

  @handle_global_errors_async
  create_comment() {
    return toggle(this, 'd_saving', async () => {
      await Comment.create(this.d_handgrading_result!.pk, {
        text: this.d_comment_text,
        location: {
          first_line: this.d_first_highlighted_line!,
          last_line: this.d_last_highlighted_line!,
          filename: this.filename,
        }
      });
      this.finish_commenting();
      this.d_show_comment_modal = false;
      this.d_comment_text = '';
    });
  }

  @handle_global_errors_async
  async delete_handgrading_comment(handgrading_comment: HandgradingComment) {
    if (!this.d_saving) {
      await toggle(this, 'd_saving', async () => {
        await handgrading_comment.delete();
        this.d_hovered_comment = null;
      });
    }
  }

  finish_commenting() {
    this.d_context_menu_is_open = false;
    this.d_first_highlighted_line = null;
    this.d_last_highlighted_line = null;
  }
}

