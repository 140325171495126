























































































































































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

import {
  AGTestSuite,
  ExpectedStudentFile,
  InstructorFile,
  Project,
  SandboxDockerImage,
} from 'ag-client-typescript';

import APIErrors from '@/components/api_errors.vue';
import Dropdown from '@/components/dropdown.vue';
import DropdownTypeahead from '@/components/dropdown_typeahead.vue';
import LastSaved from "@/components/last_saved.vue";
import Modal from '@/components/modal.vue';
import AGTestSuiteAdvancedFdbkSettings from '@/components/project_admin/ag_tests/ag_test_suite_advanced_fdbk_settings.vue';
import { AGTestSuiteFeedbackPreset, FeedbackConfigLabel, FeedbackDescriptions } from '@/components/project_admin/feedback_config_panel/feedback_config_utils';
import SuiteSettings from '@/components/project_admin/suite_settings.vue';
import SelectObject from '@/components/select_object.vue';
import Toggle from '@/components/toggle.vue';
import Tooltip from '@/components/tooltip.vue';
import ValidatedForm from '@/components/validated_form.vue';
import ValidatedInput, { ValidatorResponse } from '@/components/validated_input.vue';
import {
  handle_api_errors_async,
  handle_global_errors_async,
  make_error_handler_func
} from '@/error_handling';
import { SafeMap } from '@/safe_map';
import { deep_copy, format_datetime, toggle } from '@/utils';
import { is_not_empty } from '@/validators';

import FeedbackConfigPanel from '../feedback_config_panel/feedback_config_panel.vue';

@Component({
  components: {
    AGTestSuiteAdvancedFdbkSettings,
    APIErrors,
    DropdownTypeahead,
    FeedbackConfigPanel,
    LastSaved,
    Modal,
    SelectObject,
    SuiteSettings,
    Toggle,
    Tooltip,
    ValidatedForm,
    ValidatedInput,
  }
})
export default class AGTestSuiteSettings extends Vue {

  @Prop({required: true, type: AGTestSuite})
  ag_test_suite!: AGTestSuite;

  @Prop({required: true, type: Project})
  project!: Project;

  @Prop({default: false, type: Boolean})
  is_first_suite!: boolean;

  // {deep: true} is needed so that we detect changes to instructor_files_needed
  // and student_files_needed. Such changes can happen when an InstructorFile
  // or ExpectedStudentFile is deleted. AGTestSuites (this component's parent)
  // observes deletion of those two types and updates the _files_needed arrays.
  @Watch('ag_test_suite', {deep: true})
  on_test_suite_change(new_test_suite: AGTestSuite, old_test_suite: AGTestSuite) {
    this.d_ag_test_suite = deep_copy(new_test_suite, AGTestSuite);
  }

  d_ag_test_suite: AGTestSuite | null = null;
  d_docker_images: SandboxDockerImage[] = [];
  d_loading = true;
  d_saving = false;
  d_num_api_errors = 0;
  d_settings_form_is_valid = true;
  d_deleting = false;
  d_show_delete_ag_test_suite_modal = false;

  readonly FeedbackConfigLabel = FeedbackConfigLabel;
  readonly FeedbackDescriptions = FeedbackDescriptions;
  readonly is_not_empty = is_not_empty;
  readonly format_datetime = format_datetime;

  @handle_global_errors_async
  async created() {
    this.d_ag_test_suite = deep_copy(this.ag_test_suite, AGTestSuite);
    let global_images = await SandboxDockerImage.get_images(null);
    let course_images = await SandboxDockerImage.get_images(this.project.course);
    this.d_docker_images = global_images.concat(course_images);
    this.d_loading = false;
  }

  @handle_api_errors_async(make_error_handler_func('delete_errors'))
  delete_ag_test_suite() {
    return toggle(this, 'd_deleting', async () => {
      await this.d_ag_test_suite!.delete();
      this.d_show_delete_ag_test_suite_modal = false;
    });
  }

  @handle_api_errors_async(handle_save_ag_test_suite_settings_error)
  save_ag_test_suite_settings() {
    return toggle(this, 'd_saving', () => {
      (<APIErrors> this.$refs.api_errors).clear();
      return this.d_ag_test_suite!.save();
    });
  }

  readonly fdbk_presets = new SafeMap<string, AGTestSuiteFeedbackPreset>([
    [
      'Public Setup',
      {
        show_individual_tests: true,
        show_setup_return_code: true,
        show_setup_timed_out: true,
        show_setup_stdout: true,
        show_setup_stderr: true
      }
    ],
    [
      'Pass/Fail Setup',
      {
        show_individual_tests: true,
        show_setup_return_code: true,
        show_setup_timed_out: true,
        show_setup_stdout: false,
        show_setup_stderr: false
      }
    ],
    [
      'Private Setup',
      {
        show_individual_tests: true,
        show_setup_return_code: false,
        show_setup_timed_out: false,
        show_setup_stdout: false,
        show_setup_stderr: false
      }
    ]
  ]);
}

function handle_save_ag_test_suite_settings_error(component: AGTestSuiteSettings, error: unknown) {
  let api_errors_elt = <APIErrors> component.$refs.api_errors;
  api_errors_elt.show_errors_from_response(error);
  if (component.d_num_api_errors !== 0) {
    api_errors_elt.$el.scrollIntoView({behavior: 'smooth'});
  }
}
