



























































































import { Component, Inject, Prop, Vue, Watch } from 'vue-property-decorator';

import { Group, GroupInvitation, Project, User } from 'ag-client-typescript';

import { GlobalData } from '@/app.vue';
import APIErrors from '@/components/api_errors.vue';
import Modal from '@/components/modal.vue';
import { handle_api_errors_async } from '@/error_handling';
import { deep_copy } from '@/utils';

@Component({
  components: {
    APIErrors,
    Modal
  }
})
export default class InvitationReceived extends Vue {
  @Inject({from: 'globals'})
  globals!: GlobalData;
  d_globals = this.globals;

  @Prop({required: true, type: GroupInvitation})
  value!: GroupInvitation;

  @Prop({required: true, type: Project})
  project!: Project;

  @Watch('value')
  on_value_changed(new_value: GroupInvitation, old_value: GroupInvitation) {
      this.d_invitation = deep_copy(new_value, GroupInvitation);
  }

  d_accepting = false;
  d_invitation: GroupInvitation | null = null;
  d_loading = true;
  d_rejecting = false;
  d_show_confirm_accept_invitation_modal = false;
  d_show_confirm_reject_invitation_modal = false;

  created() {
    this.d_invitation = deep_copy(this.value, GroupInvitation);
  }

  member_acceptance_status(username: string) {
    if (username === this.d_invitation!.sender_username) {
        return 'Sender';
    }
    return this.d_invitation!.recipients_who_accepted.findIndex(
       (name: string) => username === name) !== -1 ? 'Accepted' : 'Pending';
  }

  @handle_api_errors_async(handle_accept_invitation_error)
  async accept_invitation() {
    try {
      this.d_accepting = true;
      (<APIErrors> this.$refs.accept_invitation_api_errors).clear();
      await this.d_invitation!.accept();
      let copy_of_invitation = deep_copy(this.d_invitation!, GroupInvitation);
      this.$emit('input', copy_of_invitation);
      this.d_show_confirm_accept_invitation_modal = false;
    }
    finally {
      this.d_accepting = false;
    }
  }

  get already_accepted() {
    let index = this.d_invitation!.recipients_who_accepted.findIndex(
      (invitee: string) => invitee === this.d_globals.current_user!.username
    );
    return index !== -1;
  }

  get other_group_members() {
    let other_invitees = [this.d_invitation!.sender_username];
    this.d_invitation!.recipient_usernames.forEach((invitee: string) => {
      if (invitee !== this.d_globals.current_user!.username) {
        other_invitees.push(invitee);
      }
    });
    return other_invitees;
  }

  @handle_api_errors_async(handle_reject_invitation_error)
  async reject_invitation() {
    (<APIErrors> this.$refs.reject_invitation_api_errors).clear();
    this.d_rejecting = true;
    await this.d_invitation!.reject();
    this.d_show_confirm_reject_invitation_modal = false;
    this.$emit('invitation_rejected');
    this.d_rejecting = false;
  }
}

function handle_reject_invitation_error(component: InvitationReceived, error: unknown) {
  (<APIErrors> component.$refs.reject_invitation_api_errors).show_errors_from_response(error);
}

function handle_accept_invitation_error(component: InvitationReceived, error: unknown) {
  (<APIErrors> component.$refs.accept_invitation_api_errors).show_errors_from_response(error);
}

