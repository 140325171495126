







































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

import { CorrectnessLevel } from '@/components/project_view/submission_detail/correctness';
import CorrectnessIcon from "@/components/project_view/submission_detail/correctness_icon.vue";

@Component({
  components: {
    CorrectnessIcon
  }
})
export default class ResultPanel extends Vue {
  @Prop({required: true, type: String})
  name!: string;

  @Prop({required: true, type: String})
  correctness_level!: CorrectnessLevel;

  @Prop({default: 0, type: Number})
  points_awarded!: number;

  @Prop({default: 0, type: Number})
  points_possible!: number;

  @Prop({default: false, type: Boolean})
  open_initially!: boolean;

  @Prop({default: false, type: Boolean})
  is_command!: boolean;

  @Prop({default: false, type: Boolean})
  is_multi_command_case!: boolean;

  d_is_open = false;

  created() {
    this.d_is_open = this.open_initially;
  }

  get panel_correctness() {
    if (this.correctness_level === CorrectnessLevel.not_available) {
      return CorrectnessLevel.not_available;
    }
    else if (this.correctness_level === CorrectnessLevel.all_correct) {
      return CorrectnessLevel.all_correct;
    }
    else if (this.correctness_level === CorrectnessLevel.none_correct) {
      return CorrectnessLevel.none_correct;
    }
    else if (this.correctness_level === CorrectnessLevel.info_only) {
      return CorrectnessLevel.info_only;
    }
    return CorrectnessLevel.some_correct;
  }

  toggle_d_is_open() {
    let top = this.$el.getBoundingClientRect().top;
    if (this.correctness_level !== CorrectnessLevel.not_available) {
      this.d_is_open = !this.d_is_open;
    }
    // This prevents any open panels below this one from being pushed
    // into the top of the viewport due to the size change of the parent.
    // istanbul ignore next
    if (!this.d_is_open && top < 0) {
      this.$nextTick(() => {
        this.$el.scrollIntoView();
      });
    }
  }
}
