































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

import Dropdown from '@/components/dropdown.vue';

@Component({
  components: { Dropdown }
})
export default class DropdownTypeahead extends Vue {

  @Prop({required: true, type: Array})
  choices!: object[];

  @Prop({required: true, type: String})
  placeholder_text!: string;

  @Prop({required: true, type: Function})
  filter_fn!: (item: object, filter: string) => boolean;

  @Prop({default: "search-field", type: String})
  typeahead_class!: string;

  filter_text: string = "";
  private _filtered_choices: object[] = [];
  private d_mounted_called = false;

  mounted() {
    // When this is true, we can safely access $refs in the template.
    this.d_mounted_called = true;
  }

  resume_search(key: KeyboardEvent) {
    let dropdown = <Dropdown> this.$refs.dropdown_component;
    if (!dropdown.is_open) {
      // don't want to automatically select what was previously selected
      if (key.code !== "Enter") {
        dropdown.show();
      }
    }
  }

  clear_filter_text() {
    this.filter_text = "";
  }

  get filtered_choices() {
    if (this.filter_text === "") {
      return this.choices;
    }
    this._filtered_choices = this.choices.filter(
      (item) => this.filter_fn(item, this.filter_text));
    return this._filtered_choices;
  }

}
